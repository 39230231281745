@import url(https://fonts.googleapis.com/css2?family=Cherish&family=M+PLUS+1+Code&display=swap);
body {
  background: #130028;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.language {
    display: inline;
  }
  .heading {
    font-size: 20px;
    color: white;
    font-family: 'Bungee Spice', cursive;

  }
  #source {
    width: 100% !important;
    background: #cccccc52;
    height: 50vh;
    font-size: 20px;
    resize: vertical;
    color: #0dff95;
    padding: 10px;
    font-family: 'M PLUS 1 Code', sans-serif;
  }
  #input {
    background: #cccccc52;
    width: 45.6%;
    height: 17vh;
    resize: vertical;
    font-size: 17px;
  }
  #output {
    background: #ffffff52;
    width: 50%;
    height: 90%;
    position: fixed;
    top: 75px;
    font-size: 18px;
    right: 2px;
    resize: none;
    color: white;
  }
  .language {
    width: 150px;
  }
  

  /* <!-- HTML !--> */
/* <button class="button-85" role="button">Button 85</button> */

/* CSS */
.button-85 {
  padding: 7px 38px 7px 34px;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  -webkit-animation: glowing-button-85 20s linear infinite;
          animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@-webkit-keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}
